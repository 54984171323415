import { useMemo } from 'react';

import { SignInSteps } from '../../types/SignInSteps';

import useCurrentUserData from './useCurrentUserData';

export default function useSignInStep() {
  const { data: userData, isLoading: userDataLoading } = useCurrentUserData();
  const twitterUsername = userData?.username ?? undefined;

  const signInStep = useMemo(() => {
    if (userDataLoading) {
      return SignInSteps.UserDataLoading;
    }
    if (!twitterUsername) {
      return SignInSteps.TwitterNotConnected;
    }
    if (!userData?.wallet_address) {
      return SignInSteps.WalletNotConnected;
    }

    if (userData?.user_type === null) {
      return SignInSteps.UserWithoutType;
    } else {
      return SignInSteps.SignupCompleted;
    }
  }, [
    twitterUsername,
    userData?.user_type,
    userData?.wallet_address,
    userDataLoading,
  ]);

  return signInStep;
}
