import { Box, Typography } from '@mui/material';

import { LinkTwitterButton } from '../shared/Buttons/LinkTwitterButton';

export function TwitterNotConnectedStep() {
  return (
    <Box
      pt={{ xs: '32px', md: '284px' }}
      gap={6}
      display="flex"
      flexDirection="column"
      width={{ xs: '350px', md: '288px' }}
    >
      <Typography
        fontSize={{ xs: 32, md: 40 }}
        fontWeight={500}
        lineHeight={{ xs: '39px', md: '48px' }}
      >
        Continue to
        <br /> Altcoinist
      </Typography>

      <LinkTwitterButton sx={{ width: '175px' }} />
    </Box>
  );
}
