import { Box, Typography, useTheme } from '@mui/material';

import { CheckedRoundedIcon } from './icons';

export function SignUpState({
  step,
  userIdInUrl,
}: {
  step: number;
  userIdInUrl: string | undefined;
}) {
  const theme = useTheme();
  if (step === 0) {
    return;
  }
  const isStep2 = step >= 2;
  return (
    <Box pt={6} mb={{ xs: 4, md: 6 }}>
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <CheckedRoundedIcon />
        <Box width={81} height={2} bgcolor={theme.palette.primary.main} />
        {isStep2 ? (
          <CheckedRoundedIcon />
        ) : (
          <Box
            width={12}
            height={12}
            border={1}
            borderRadius={20}
            borderColor={theme.palette.primary.main}
          />
        )}
        <Box
          width={81}
          height={2}
          bgcolor={
            isStep2 ? theme.palette.primary.main : theme.palette.grey[500]
          }
        />
        <Box
          width={12}
          height={12}
          border={1}
          borderRadius={20}
          borderColor={
            isStep2 ? theme.palette.primary.main : theme.palette.grey[500]
          }
        />
      </Box>
      <Box display="flex" flexDirection="row" gap={6} pt={2}>
        <Typography width="78px" variant="body2">
          Connect X
        </Typography>
        <Typography
          width="78px"
          variant="body2"
          color={isStep2 ? 'white' : theme.palette.primary.main}
        >
          Connect wallet
        </Typography>
        <Typography
          width="80px"
          variant="body2"
          color={isStep2 ? theme.palette.primary.main : theme.palette.grey[500]}
        >
          {userIdInUrl ? 'Review' : 'Choose user type'}
        </Typography>
      </Box>
    </Box>
  );
}
