import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { SiweMessage } from 'siwe';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';

import { Backdrop, Box, Button, Typography } from '@mui/material';

import { useGetLinkWallet } from '../../api/useGetLinkWallet';
import { usePostLinkWallet } from '../../api/users/mutations/usePostLinkWallet';
import { GlobalLoading } from '../GlobalLoading';

export function WalletLinkingStep() {
  const { enqueueSnackbar } = useSnackbar();
  const { address, chainId } = useAccount();
  const { disconnect } = useDisconnect();

  const [isLinking, setIsLinking] = useState(false);

  const {
    data: getLinkData,
    isLoading: isGetLinkLoading,
    error,
  } = useGetLinkWallet();

  const { signMessageAsync, ...signMessageRest } = useSignMessage();

  const postLinkMutation = usePostLinkWallet();

  const handleLinkWalletClick = async () => {
    try {
      if (!address || !chainId || !getLinkData) return;

      setIsLinking(true);

      const { data, error } = await linkWallet(
        address,
        chainId,
        getLinkData.nonce,
        getLinkData.sig,
      );

      if (!data || error) {
        setIsLinking(false);

        enqueueSnackbar(
          error ?? 'Something went wrong while linking the wallet',
          {
            variant: 'error',
          },
        );
        return;
      }

      window.location.reload();
    } catch (error) {
      setIsLinking(false);
    }
  };

  const linkWallet = async (
    address: string,
    chainId: number,
    nonce: string,
    hmac: string,
  ) => {
    const message = generateSiweMessage(address, chainId, nonce);

    const signature = await signMessageAsync({
      message: message.prepareMessage(),
    });

    return await postLinkMutation.mutateAsync({
      message,
      signature,
      hmac,
    });
  };

  return (
    <>
      {(isLinking || isGetLinkLoading) && (
        <Backdrop open>
          <GlobalLoading />
        </Backdrop>
      )}

      <Typography
        fontSize={{ xs: 32, md: 40 }}
        fontWeight={500}
        lineHeight={{ xs: '38px', md: '48px' }}
        pt={{ xs: 12, md: 50 }}
        width="288px"
      >
        Link your wallet
      </Typography>

      <Typography
        color="text.secondary"
        fontSize={14}
        fontWeight={300}
        lineHeight="18px"
        mt={2}
        mb={6}
      >
        Linking your wallet allows us to save your wallet to our database to
        verify any upcoming transactions.
      </Typography>

      <Typography
        component="span"
        fontSize={14}
        fontWeight={300}
        lineHeight={'18px'}
      >
        {address}
      </Typography>
      <Box display="flex" gap={4} sx={{ mt: 6 }} pb={{ xs: '40px', md: 0 }}>
        <Button
          variant="outlined"
          disabled={isLinking}
          onClick={() => disconnect()}
          color="error"
        >
          Disconnect
        </Button>

        <Button
          size="large"
          variant="contained"
          onClick={handleLinkWalletClick}
          disabled={!getLinkData?.nonce || isLinking}
        >
          Link your wallet
        </Button>
      </Box>
    </>
  );
}

function generateSiweMessage(address: string, chainId: number, nonce: string) {
  return new SiweMessage({
    domain: window.location.host,
    address,
    chainId,
    uri: window.location.origin,
    version: '1',
    statement: 'Sign in with Base to the app.',
    nonce,
  });
}
