import { Button, ButtonProps } from '@mui/material';

import { useUserAuthActions } from '../../../hooks/useUserAuthActions';
import { XIcon } from '../../icons';

export function LinkTwitterButton({ ...props }: ButtonProps) {
  const { linkTwitter } = useUserAuthActions();

  return (
    <Button endIcon={<XIcon />} onClick={linkTwitter} {...props}>
      Continue with
    </Button>
  );
}
