import { useEffect } from 'react';
import { useAccount } from 'wagmi';

import { Link, Typography } from '@mui/material';

import { SMART_WALLET_GUIDE } from '../../constants/externalLinks';
import { usePostHogCapture } from '../../hooks/usePostHogCapture';
import { CoinbaseSmartWalletButton } from '../shared/Buttons/CoinbaseSmartWalletButton';

import { WalletLinkingStep } from './WalletLinkingStep';

export function WalletConnectStep() {
  const { isConnected } = useAccount();

  // Gotta track X connect here, cause the sessions are not set propperly on SessionProvider.
  const { captureConnectX } = usePostHogCapture();
  useEffect(() => captureConnectX(), []);

  return (
    <>
      {!isConnected && (
        <>
          <Typography
            fontSize={{ xs: 32, md: 40 }}
            fontWeight={500}
            lineHeight={{ xs: '38px', md: '48px' }}
            pt={{ xs: 12, md: 50 }}
            width={{ xs: '350px', md: '288px' }}
          >
            Connect Wallet
          </Typography>

          <Typography
            color="text.secondary"
            fontSize={14}
            fontWeight={300}
            mb={6}
            mt={2}
          >
            <Link
              href={SMART_WALLET_GUIDE}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Click here
            </Link>{' '}
            for our Coinbase Smart Wallet Guide.
          </Typography>
          <CoinbaseSmartWalletButton />
        </>
      )}

      {isConnected && <WalletLinkingStep />}
    </>
  );
}
